export const SiteActionsCode = {
  ADD: 'SI001',
  EDIT: 'SI002',
  DEACTIVE: 'SI003',
  SEE_ALL_SITE: 'SI004',
};

export const UserActionsCode = {
  ADD: 'US001',
  EDIT: 'US002',
  DELETE: 'US003',
};

export const RoleActionsCode = {
  EDIT: 'RM002',
};

export const InvoiceActionsCode = {
  VIEW_PDF: 'EV009',
};

export const SymbolActionsCode = {
  ADD: 'IS001',
  EDIT: 'IS002',
  DELETE: 'IS003',
};

export const PageCodes = {
  UserManagement: 'US000',
  RoleManagement: 'RM000',
};
