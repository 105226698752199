import { mapGetters } from 'vuex';
import { PageCodes } from '@/@core/data/enum/menuCode';
import { getAccountListApi } from '@/api/action-defines/user-management';

export default {
  components: {},
  data() {
    return {
      totalUser: 0,
    };
  },
  computed: {
    ...mapGetters({
      $lsAllowAccessPage: 'app/allowAccessPage',
    }),
    allowAccessUserManagement() {
      return this.$lsAllowAccessPage(PageCodes.UserManagement);
    },
    allowAccessRoleManagement() {
      return this.$lsAllowAccessPage(PageCodes.RoleManagement);
    },
    routeAllowAccess() {
      const routes = [];
      if (this.allowAccessUserManagement) {
        routes.push('user-management-account');
      }
      if (this.allowAccessRoleManagement) {
        routes.push('user-management-role');
      }
      return routes;
    },
    tabs() {
      const allTabs = [
        {
          title: this.$t('user_management_account'),
          route: 'user-management-account',
          active: false,
        },
        {
          title: this.$t('user_management_role'),
          route: 'user-management-role',
          active: false,
        },
      ];
      return allTabs.filter((s) => this.routeAllowAccess.includes(s.route));
    },
  },
  created() {
    this.tabs.forEach((x) => {
      if (x.route == this.$route.name) {
        x.active = true;
      } else {
        x.active = false;
      }
    });
    this.getAccountList();
  },
  watch: {
    $route(value) {
      this.tabs.forEach((x) => {
        if (x.route == this.$route.name) {
          x.active = true;
        } else {
          x.active = false;
        }
      });
    },
  },
  methods: {
    loadRoute(routeName) {
      this.$router.push({ name: routeName });
    },
    getAccountList() {
      getAccountListApi().then((res) => {
        this.totalUser = res.Data.length;
      });
    },
  },
};
